.navbar {
    position: fixed;
    width: 100%;
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    background-color: transparent;
    z-index: 1000;
}

.logo {
    width: 220px;
    transition: 0.4s;
}

.logoScroll {
    width: 150px;
    transition: 0.4s;
}

.topbar {
    padding-top: 20px;
    padding-right: 0px;
    display: flex;
    justify-content: flex-end;
}

.topbar a {
    height: 40px;
}

.navbarBtn {
    background-color: transparent;
    color: white;
    text-shadow: 1px 1px 1px #000 !important;
    font-size: 20px;
    font-weight: bold;
    border: 0;
    transition: 0.2s;
    text-shadow: 0px 0px 2px #fefcf1;
    width: 140px;
    height: 40px;
}

.navbarBtn:hover {
    color: white;
}

.navbarBtn:active {
    color: white;
}

.navbarBtn:focus {
    color: white;
}