.imgsContainer {
    display: block;
    padding-top: 0px;
}

.header {
    display: block;
    width: 100%;
    height: 100vh;
    position: relative;
    margin-bottom: 50px;
    background: linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 1));
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
    background: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 1));
}

.imgsContainerFirst {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    overflow-y: hidden;
    /* margin-bottom: -50px; */
}

.imgsContainerFirstMobile {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    height: 100vh;
    width: 100%;
    overflow: hidden !important;
}


.imgsContainerFirstMobile video {
    margin-left: 50px;
}

.home__img--0 {
    width: 100%;
    /* height: 100vh; */
    object-fit: cover;
    object-position: center 0px;
    padding: 0;
    margin-bottom: 0px;
}

.home__img--1 {
    width: 25%;
    height: auto;
    /* box-shadow: 10px 6px 20px rgba(84, 84, 84, 0.6); */
    padding: 0;
}

.home__img--2 {
    width: 80%;
}

.home__img--3 {
    width: 45%;
    height: 55%;
    /* box-shadow: 10px 6px 20px rgba(84, 84, 84, 0.6); */
    margin-top: 50px;
    padding: 0;
}

.home__img--4 {
    width: 25%;
    height: 25%;
    /* box-shadow: 10px 6px 20px rgba(84, 84, 84, 0.6); */
    margin-left: 50px;
    margin-top: auto;
    margin-bottom: auto;
}

.home__img--5 {
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    position: absolute;
    object-fit: cover;
}

.home__img--6 {
    width: 30%;
    height: 30%;
    /* box-shadow: 10px 6px 20px rgba(84, 84, 84, 0.6); */
    margin-bottom: 50px;
}

.section {
    position: relative;
    width: 100% !important;
    overflow-x: hidden;
    margin-top: 40px;
    margin-bottom: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.overlaySection {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 5;
    background: linear-gradient(rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 1));
}

@media screen and (max-width: 992px) {

    .imgsContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: 0px;
    }

    .home__img--0 {
        width: 100%;
        height: 100vh;
        object-fit: cover;
        object-position: 45% 0px;
        padding: 0;
        margin-bottom: 0px;
    }

    .home__img--1 {
        width: 80%;
        height: auto;
        /* box-shadow: 10px 6px 20px rgba(84, 84, 84, 0.6); */
        margin-top: 20px;
        margin-left: 0px;
    }

    .home__img--2 {
        width: 150%;
        height: auto;
        margin-left: 0%;
    }

    .home__img--3 {
        width: 80%;
        height: auto;
        /* box-shadow: 10px 6px 20px rgba(84, 84, 84, 0.6); */
        margin-top: 20px;
        margin-left: 0px;
    }

    .home__img--4 {
        width: 80%;
        height: auto;
        /* box-shadow: 10px 6px 20px rgba(84, 84, 84, 0.6); */
        /* margin-top: 20px; */
        margin-left: 0px;
    }

    .home__img--6 {
        width: 80%;
        height: auto;
        /* box-shadow: 10px 6px 20px rgba(84, 84, 84, 0.6); */
        margin-top: 20px;
        margin-left: 0px;
    }

}