.menu {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.menu a {
    cursor: pointer;
    padding: 0px 10px;
    font-size: 20px;
    border: 0;
    background-color: transparent;
    color: #fff;
    height: 48px;
}

.menu > a {
    line-height: 50px;
}

.menuBtnMobile {
    opacity: 0.6;
    background-color: transparent;
    position: fixed;
    z-index: 250;
    top: 24px;
    right: 24px;
    border: none;
    width: 30px;
    height: 25px;
    outline: none;
    transition: opacity 0.2s ease-out;
}

.menuBtnMobile:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    right: auto;
    width: 100%;
    background: linear-gradient(to bottom, #000, #000 20%, transparent 20%, transparent 40%, #000 40%, #000 60%, transparent 60%, transparent 80%, #000 80%, #000 100%);
    transition: opacity 0.2s ease-out, width 0.2s 0.2s ease-out;
}

.menuBtnMobile:after {
    opacity: 0;
    content: '×';
    color: white;
    position: absolute;
    top: 16px;
    left: -4px;
    font-family: Arial, sans-serif;
    font-size: 60px;
    line-height: 0;
    transition: opacity 0.4s ease-out;
}

.menuBtnMobile:active {
    transform: translateY(2px);
}

.menuBtnMobile:hover {
    opacity: 1;
}

.open .menuBtnMobile {
    opacity: 1;
}

.open .menuBtnMobile:before {
    opacity: 0;
    width: 0;
}

.open .menuBtnMobile:after {
    opacity: 1;
    transform: translate3d(0, 0, 0) rotate(360deg);
    transition: transform 0.4s 1s ease-out, opacity 0.4s 1s ease-out;
}

nav {
    z-index: 100;
    position: fixed;
    top: -100%;
    left: 0;
    width: 100%;
    height: 100%;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    overflow: hidden;
}

nav:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    background: rgba(0, 0, 0, 0.8);
    width: 100%;
    height: 0;
    padding-bottom: 100%;
    border-radius: 100%;
    transform: scale(0.04), translateY(9999px);
    overflow: hidden;
}

.open nav {
    top: 0;
}

.open nav:before {
    animation: menu-animation 0.8s ease-out forwards;
}

.menu {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    backface-visibility: hidden;
    perspective: 1000;
    color: white;
}

.menu a {
    opacity: 0;
    text-align: center;
    transform: translate3d(0, 36px, 0);
}

.menu .accordionWrapper {
    opacity: 0 !important;
    text-align: center !important;
    transform: translate3d(0, 36px, 0) !important;
}

.menu a:not(.accordionWrapper a):before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    left: auto;
    background-color: white;
    height: 100%;
    width: 0;
    overflow: hidden;
    transition: width 0.14s ease-out;
}

.menu a:not(.accordionWrapper a):after {
    opacity: 0;
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    color: #fff;
    overflow: hidden;
    transform: translate(-24px, 6px);
    transition: transform 0.1s ease-out, opacity 0.1s ease-out;
}

.menu a:not(.accordionWrapper a):focus:before {
    left: 0;
    right: auto;
    width: 100%;
}

.menu a:not(.accordionWrapper a):focus:after {
    opacity: 1;
    padding: 0 20px;
    transform: translate(0px, 6px);
    transition: transform 0.2s 0.14s ease-out, opacity 0.2s 0.14s ease-out;
}

.open .menu a {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    transition: transform 0.2s ease-out, opacity 0.2s ease-out;
}

.open .menu .accordionWrapper {
    opacity: 1 !important;
    transform: translate3d(0, 0, 0) !important;
    transition: transform 0.2s ease-out, opacity 0.2s ease-out !important;
}

.open .menu a:nth-child(1) {
    transition-delay: 0.60s;
}

/* .open .menu .accordionWrapper:nth-child(2) {
    transition-delay: 0.55s !important;
}

.open .menu .accordionWrapper:nth-child(3) {
    transition-delay: 0.60s !important;
}

.open .menu .accordionWrapper:nth-child(4) {
    transition-delay: 0.65s !important;
} */

.open .menu a:nth-child(2) {
    transition-delay: 0.65s;
}

.open .menu a:nth-child(3) {
    transition-delay: 0.70s;
}

.open .menu a:nth-child(4) {
    transition-delay: 0.75s;
}

.open .menu a:nth-child(5) {
    transition-delay: 0.8s;
}

.open .menu a:nth-child(6) {
    transition-delay: 0.85s;
}


article {
    padding: 24px;
    max-width: 600px;
    margin: 60px auto;
    transition: 0.2s ease-out;
}

.open article {
    transform: scale(0.92);
    transition: transform 0.2s 0.41s ease-out;
}

@keyframes menu-animation {
    0% {
        opacity: 0;
        transform: scale(0.04) translateY(300%);
    }

    40% {
        transform: scale(0.04) translateY(0);
        transition: ease-out;
    }

    40% {
        transform: scale(0.04) translateY(0);
    }

    60% {
        opacity: 1;
        transform: scale(0.02) translateY(0px);
    }

    61% {
        transform: scale(0.04);
    }

    99.9% {
        height: 0;
        padding-bottom: 100%;
        border-radius: 100%;
    }

    100% {
        transform: scale(2);
        height: 100%;
        padding-bottom: 0;
        border-radius: 0;
    }
}

.navContainerHome{
    position: relative;
}

.navContainer{
    position: relative;
    padding-top: 120px;
}

.mark {
    position: absolute;
    width: 130px;
    top: 0px;
    left: 5px;
    z-index: 1;
}