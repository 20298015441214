.footer {
    margin-top: 50px;
    width: 100%;
    height: 80px;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: black;
    font-size: 14px;
}

@media screen and (max-width: 992px) {
    .footer {
        font-size: 12px;
    }

}