.sustainableMission {
    width: 400px;
    /* text-align: center; */
    margin-top: 100px;
}

.sustainableFabrics {
    width: 400px;
    /* text-align: center; */
    margin-bottom: 100px;
}

.sust{
    width: 65%;
}

@media screen and (max-width: 992px) {
    .sustainableMission {
        width: 90%;
        margin-top: 20px;
    }

    .sustainableFabrics {
        width: 90%;
        margin-bottom: 100px;
    }

    .sust{
        width: 90%;
    }

}