body {
  margin: 0;
  background-color: #fff;
}

* {
  /* font-family: myFont; */
  text-decoration: none !important;
  font-family: 'Josefin Sans', sans-serif !important;
}

button {
  font-weight: 300 !important;
}

#root {
  min-height: 100vh;
}

/* @font-face {
  font-family: myFont;
  src: url(./assets/Rajdhani-Light.ttf);
}

@font-face {
  font-family: myFontBold;
  src: url(./assets/Rajdhani-Bold.ttf);
} */

::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

::-webkit-scrollbar-thumb {
  background: rgb(96, 96, 96);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: rgb(68, 68, 68)
}

.MuiAccordionSummary-content {
  margin: 0 !important;
  justify-content: center;
}

.MuiAccordionDetails-root {
  padding: 0 !important;
  margin: 0 !important;
}

.containerCollections {
  width: 100%;
  padding-top: 100px;
}

.collectionsItem {
  padding: 30px;
}

.px-about {
  padding-left: 12rem;
  padding-right: 12rem;
}

.manifesto {
  width: 70%;
  height: 70%;
}

.insta {
  width: 4%;
  height: auto;
}

.mt-custom {
  margin-top: 150px;
}

@media screen and (max-width: 992px) {
  .containerCollections {
    padding-top: 0px;
  }

  .px-about {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .manifesto {
    width: 90%;
  }

  .insta {
    width: 10%;
  }

  .mt-custom {
    margin-top: 0px;
  }

}

.d-contents {
  display: contents;
}